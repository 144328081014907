<template>
  <div>
    <b-modal
      id="modal-login"
      hide-footer
      cancel-variant="outline-secondary"
      ok-title="Show data"
      centered
      no-close-on-backdrop
      title="Filters"
    >
      <b-form>
        <b-form-group
          label="Class"
          invalid-feedback="class is required."
          ref="class_current"
        >
          <v-select
            v-model="filter.cls"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :clearable="false"
            :options="[{ name: 'All Classes', id: 0 }, ...classesOptions]"
            label="name"
            :reduce="(val) => val.id"
            input-id="id"
            ref="class_current"
            @input="FillSectionsCurrent()"
            :disabled="filterloading"
            placeholder="Select class."
          />
          <!-- @search:blur="CheckClassCurrent()" -->
        </b-form-group>

        <b-form-group
          label="Section"
          invalid-feedback="Section name is required."
          ref="section_current"
        >
          <v-select
            v-model="filter.sec"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="currentSections"
            :reduce="(opt) => opt.id"
            label="section"
            :clearable="false"
            :disabled="filterloading"
            placeholder="Select section."
          />
          <!-- @search:blur="CheckCurrentSec()" -->
        </b-form-group>

        <!-- <b-form-group
          label="Gender"
          invalid-feedback="gender is required."
          ref="gender"
        >
          <v-select
            v-model="filter.gender"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="genders"
            :clearable="false"
            :disabled="filterloading"
            placeholder="Select gender."
          />
        </b-form-group> -->

        <b-form-group
          label="Status"
          invalid-feedback="status is required."
          ref="status"
        >
          <v-select
            v-model="filter.status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterStatusOpt"
            :reduce="(opt) => opt.value"
            label="text"
            :clearable="false"
            :disabled="filterloading"
            placeholder="Select status."
          />
        </b-form-group>
      </b-form>
      <b-row class="mt-2">
        <b-col style="display: flex; justify-content: end">
          <b-button
            @click="Filter(1)"
            style="padding: 12px 16px"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
          >
            <span class="align-middle">Show data</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="modal-reg"
      hide-footer
      cancel-variant="outline-secondary"
      ok-title="Show data"
      centered
      no-close-on-backdrop
      title="Filters"
    >
      <b-form>
        <b-form-group
          label="Class"
          invalid-feedback="class is required."
          ref="class_current"
        >
          <v-select
            v-model="filter.cls"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="[{ name: 'All Classes', id: 0 }, ...classesOptions]"
            :clearable="false"
            label="name"
            :reduce="(val) => val.id"
            input-id="id"
            ref="class_current"
            @input="FillSectionsCurrent()"
            :disabled="filterloading"
            placeholder="Select class."
          />
          <!-- @search:blur="CheckClassCurrent()" -->
        </b-form-group>

        <b-form-group
          label="Section"
          invalid-feedback="Section name is required."
          ref="section_current"
        >
          <v-select
            v-model="filter.sec"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="currentSections"
            :reduce="(opt) => opt.id"
            label="section"
            :clearable="false"
            :disabled="filterloading"
            placeholder="Select section."
          />
          <!-- @search:blur="CheckCurrentSec()" -->
        </b-form-group>

        <b-form-group
          label="Status"
          invalid-feedback="status is required."
          ref="reg_status"
        >
          <v-select
            v-model="filter.status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterStatusOpt"
            :reduce="(opt) => opt.value"
            label="text"
            :clearable="false"
            :disabled="filterloading"
            placeholder="Select status."
          />
        </b-form-group>

        <b-form-group
          label="Date For"
          invalid-feedback="status is required."
          ref="dt_type"
        >
          <v-select
            v-model="regFilter.dateType"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="dtOptions"
            :reduce="(opt) => opt.value"
            label="text"
            :clearable="false"
            :disabled="filterloading"
            placeholder="Select type"
          />
        </b-form-group>
        <b-form-group
          label="Date"
          invalid-feedback="Date is required."
          ref="date"
        >
          <flat-pickr
            v-model="filterDate"
            :config="config"
            class="form-control"
            placeholder="Select Date."
          />
          <!-- @on-change="setDate" -->
        </b-form-group>
      </b-form>
      <b-row class="mt-2">
        <b-col style="display: flex; justify-content: end">
          <b-button
            @click="Filter(1)"
            style="padding: 12px 16px"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
          >
            <span class="align-middle">Show data</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="modal-cert"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      title="Print"
    >
      <b-form>
        <b-form-group
          label="Type"
          invalid-feedback="Type is required."
          ref="type"
        >
          <v-select
            ref="type"
            v-model="reportObj.type"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="certOptions"
            :clearable="false"
            label="name"
            :reduce="(val) => val.value"
            placeholder="Select type"
          />
        </b-form-group>
      </b-form>
      <b-row class="mt-2">
        <b-col style="display: flex; justify-content: end">
          <b-button
            @click="printRep()"
            style="padding: 12px 16px"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            :disabled="reportPrinting"
          >
            <b-spinner v-if="reportPrinting" small type="grow" />
            <span v-else class="align-middle">Print</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="modal-search"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      hide-header
      title="Choose Search Criteria"
      body-class="p-0"
    >
      <div class="pt-2 pl-2 pr-2 pb-0">
        <h2>Choose Search Criteria</h2>
        <hr />
      </div>
      <div>
        <h5
          v-for="item in searchOptions"
          :key="item.name"
          class="pt-50 pb-50 pl-2 pr-2 font-weight-300 mb-0 cursor-pointer"
          :class="[
            item.value == searchType && 'text-white bg-primary',
            item.value != searchType && 'search-type',
          ]"
          @click="saveState(item.value)"
        >
          {{ item.name }}
        </h5>
      </div>
      <!-- <b-form >
        <b-form-group
          label="Search by"
          invalid-feedback="Search by is required."
          ref="search"
        >
          <v-select
            ref="search"
            v-model="searchType"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="searchOptions"
            :clearable="false"
            label="name"
            :reduce="(val) => val.value"
            placeholder="Select"
            @input="saveState()"
          />
        </b-form-group>
      </b-form> -->
    </b-modal>

    <div>
      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="visibility7"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Transfer Student</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="visibility7 = false"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="">
            <b-col md="12" class="mb-1">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="44"
                    :src="chObj.picture"
                    variant="light-primary"
                  />
                </template>

                <b-link
                  class="font-weight-bold d-block text-nowrap"
                  style="margin-bottom: 4px"
                >
                  <b-badge variant="light-primary">
                    {{ chObj.name }}
                  </b-badge>
                </b-link>
                <small class="text-muted">
                  <b-badge variant="light-primary" class="mr-50">
                    GR -
                    {{
                      reportDomain === "myskoolhpgs" ||
                      reportDomain === "myskoolprofectus"
                        ? chObj.grNoLocal
                        : chObj.grNo
                    }}
                  </b-badge>
                  <b-badge variant="light-primary" class="mr-50">
                    F# - {{ chObj.family_code }}
                  </b-badge>
                  <b-badge variant="light-primary">
                    {{
                      reportDomain === "myskoolhpgs" ||
                      reportDomain === "myskoolprofectus"
                        ? "CID: "
                        : "ID: "
                    }}

                    {{
                      reportDomain === "myskoolhpgs" ||
                      reportDomain === "myskoolprofectus"
                        ? chObj.grNo
                        : chObj.id
                    }}
                  </b-badge>
                </small>
              </b-media>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Current Campus">
                <b-badge variant="primary">
                  {{ getCampus(chObj.campusID) }}
                </b-badge>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Transfer to Campus"
                invalid-feedback="Campus is required."
                ref="ch_camp"
              >
                <v-select
                  v-model="chObj.newCampus"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="campuses"
                  :clearable="false"
                  label="branch"
                  :reduce="(val) => val.id"
                  :disabled="clsLoading"
                  placeholder="Select campus"
                  @input="LoadClass()"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Class"
                invalid-feedback="class is required."
                ref="ch_cls"
              >
                <v-select
                  v-model="chObj.clsID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="chOptions"
                  :clearable="false"
                  label="name"
                  :reduce="(val) => val.id"
                  :disabled="clsLoading"
                  placeholder="Select class"
                  @input="FillChSec()"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Section"
                invalid-feedback="Section is required."
                ref="ch_sec"
              >
                <v-select
                  v-model="chObj.secID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="chSections"
                  :reduce="(opt) => opt.id"
                  label="section"
                  :clearable="false"
                  :disabled="clsLoading"
                  placeholder="Select section"
                  @input="checkChSec()"
                />
              </b-form-group>
            </b-col>

            <b-col class="mt-1" md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                class="mr-1"
                block
                :disabled="request"
                @click="saveCh()"
              >
                <b-spinner v-if="request" small type="grow" />
                <span v-else>Transfer</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="visibility"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <!-- <h5 class="mb-0">
            {{ sidebarTitle }}
          </h5> -->
          <h5 class="mb-0" v-html="sidebarTitle"></h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hideSideBar()"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="mt-1">
            <b-col cols="12">
              <b-form-group
                label="Reason"
                invalid-feedback="reason is required."
              >
                <b-form-input
                  ref="reason"
                  id="mc-first-name"
                  placeholder="Enter leaving reason"
                  v-model="leaveObj.reason"
                  @focusout="CheckReason()"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Date of leaving"
                invalid-feedback="Date of leaving is required"
                ref="leave_date"
              >
                <flat-pickr
                  :config="config"
                  v-model="leaveObj.date_of_leaving"
                  class="form-control"
                  placeholder="Select date"
                  @on-change="CheckDate()"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <span>Remaining Dues:</span>
              <b-badge variant="danger" class="ml-1">{{ totalDues }}</b-badge>
            </b-col>

            <!-- <b-col md="12">
              <b-form-group
                label="Email"
                invalid-feedback="Email address is required."
              >
                <div class="form-label-group">
                  <b-form-input
                    ref="email"
                    type="email"
                    id="mc-email"
                    placeholder="myskool@example.com"
                    v-model="myObj.email"
                    @focusout="CheckEmail()"
                  />
                </div>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Purpose "
                invalid-feedback="Email address is required."
              >
                <div class="form-label-group">
                  <b-form-input
                    ref="email"
                    type="text"
                    id="mc-email"
                    placeholder="Enter purpose here."
                    v-model="leaveObj.purpose"
                  />
                </div>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Tag">
                <div class="form-label-group">
                  <b-form-input
                    ref="tag"
                    placeholder="Enter tag here."
                    v-model="myObj.tag"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :state="nameState"
                label="Upload Documents"
                invalid-feedback="Documents are required"
                required
                ref="docs"
              >
                <b-button
                  ref="docs"
                  @click="$refs.psamplefile.click()"
                  variant="primary"
                  block
                >
                  {{ docsloading }}
                  <b-spinner
                    v-if="mydocloading"
                    type="grow"
                    label="Loading..."
                  />
                </b-button>
                <input
                  type="file"
                  id="psamplefile"
                  hidden
                  ref="psamplefile"
                  multiple
                  @change="PsampleAdd()"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <div>
                <b-badge
                  style="margin-bottom: 5px"
                  class="p-1"
                  v-for="(file, index) in myObj.attachments"
                  :key="index"
                  variant="light-primary"
                >
                  {{ index + 1 + " : " + file.slice(0, 35) }}

                  <i
                    class="text-danger fa fa-trash"
                    @click="deleteDocument(index)"
                  ></i>
                </b-badge>
              </div>
            </b-col> -->

            <b-col class="mt-1" md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                class="mr-1"
                @click="Add()"
                block
                :disabled="request"
              >
                <b-spinner v-if="request" small type="grow" />
                <span v-if="request == false"> {{ sidebarButton }} </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="visibility5"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0" v-html="sidebarTitle"></h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="visibility5 = false"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="mt-1">
            <b-col cols="12">
              <b-form-group
                label="Reason"
                invalid-feedback="Reason is required."
              >
                <b-form-input
                  ref="sus_reason"
                  id="mc-first-name"
                  placeholder="Enter suspension reason"
                  v-model.trim="susObj.suspend_reason"
                  @focusout="CheckSusReason()"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <!-- label="Suspend Till" -->
              <b-form-group invalid-feedback="Date is required" ref="sus_date">
                <div class="d-flex justify-content-between pb-50">
                  <label class="bv-no-focus-ring col-form-label p-0"
                    >Suspend Till</label
                  >
                  <span>
                    <b-badge
                      v-for="item in susOptions"
                      :key="item.value"
                      pill
                      :variant="
                        currentSelected == item.value
                          ? 'primary'
                          : 'light-primary'
                      "
                      class="mr-50 cursor-pointer"
                      @click="setTillDate(item.value)"
                    >
                      {{ item.text }}
                    </b-badge>
                  </span>
                </div>
                <flat-pickr
                  :config="config"
                  v-model="susObj.suspend_till"
                  class="form-control"
                  placeholder="Select date"
                />
                <!-- @on-change="CheckSusDate()" -->
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Suspend For"
                invalid-feedback="Suspend for is required."
                ref="sus_for"
              >
                <b-form-checkbox
                  class="mb-1"
                  v-model="susObj.suspend_for_attentance"
                >
                  Attendance
                </b-form-checkbox>
                <b-form-checkbox
                  class="mb-1"
                  v-model="susObj.suspend_for_homework"
                >
                  Homework
                </b-form-checkbox>
                <b-form-checkbox
                  class="mb-1"
                  v-model="susObj.suspend_for_exams"
                >
                  Examination
                </b-form-checkbox>
                <b-form-checkbox
                  class="mb-1"
                  v-model="susObj.suspend_for_messaging"
                >
                  Messages
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col class="mt-1" md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                class="mr-1"
                @click="Suspend()"
                block
                :disabled="request"
              >
                <b-spinner v-if="request" small type="grow" />
                <span v-else> Suspend </span>
              </b-button>
            </b-col>
            <b-col class="mt-1" md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="danger"
                class="mr-1"
                @click="ClearSuspend()"
                block
                :disabled="request"
              >
                <b-spinner v-if="request" small type="grow" />
                <span v-else> Clear Suspension </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="visibility2"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Import</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="visibility2 = false"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="">
            <b-col md="12" class="pt-1 pl-1" v-if="rights.add">
              <b-button
                style="margin: 5px 0px"
                @click="$refs.importFile.click()"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="stdImporting"
                block
              >
                <b-spinner
                  v-if="stdImporting"
                  small
                  variant="light"
                  label="Spinning"
                ></b-spinner>
                <div v-else>
                  <input
                    type="file"
                    id="importFile"
                    hidden
                    ref="importFile"
                    @change="Import()"
                  />
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span class="align-middle">Import </span>
                </div>
              </b-button>
            </b-col>
            <b-col md="12" class="pt-1 pl-1" v-if="rights.add">
              <b-button
                style="margin: 5px 0px"
                @click="$refs.importFee.click()"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="feeImporting"
                block
              >
                <b-spinner
                  v-if="feeImporting"
                  small
                  variant="light"
                  label="Spinning"
                ></b-spinner>
                <div v-else>
                  <input
                    type="file"
                    id="importFee"
                    hidden
                    ref="importFee"
                    @change="ImportFee()"
                  />
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span class="align-middle"> Fee</span>
                </div>
              </b-button>
            </b-col>
            <!-- <b-col md="12" class="pt-1 pl-1" v-if="rights.add">
              <b-button
                style="margin: 5px 0px"
                @click="$refs.importStaff.click()"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="staffImporting"
                block
              >
                <b-spinner
                  v-if="staffImporting"
                  small
                  variant="light"
                  label="Spinning"
                ></b-spinner>
                <div v-else>
                  <input
                    type="file"
                    id="importStaff"
                    hidden
                    ref="importStaff"
                    @change="ImportStaff()"
                  />
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span class="align-middle">Staff</span>
                </div>
              </b-button>
            </b-col> -->
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="visibility4"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Export</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="visibility4 = false"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="">
            <b-col cols="12">
              <b-form-checkbox
                v-for="(data, ind) in exportSchema"
                :key="ind"
                class="mb-1"
                v-model="data.status"
              >
                {{ data.text }}
              </b-form-checkbox>
            </b-col>
            <b-col md="12" class="pt-1 pl-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="exporting"
                block
                @click="exportNew()"
              >
                <b-spinner v-if="exporting" small varaint="primary" />
                <span v-else>Export</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="visibility3"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Generate Family Code</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="visibility3 = false"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="">
            <b-col md="12" class="mb-1">
              <b-button
                @click="codeBy = false"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :variant="!codeBy ? 'primary' : 'outline-primary'"
                block
              >
                Generate By CNIC
              </b-button>
            </b-col>
            <b-col md="12">
              <b-button
                @click="codeBy = true"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :variant="codeBy ? 'primary' : 'outline-primary'"
                block
              >
                Generate By Contact Number
              </b-button>
            </b-col>
            <b-col md="12" class="mt-2">
              <b-button
                @click="assignCode()"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                :disabled="assignLoading"
                block
              >
                <b-spinner
                  v-if="assignLoading"
                  small
                  variant="light"
                  label="assigning"
                ></b-spinner>
                <span v-else> Assign Family Code </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-sidebar
        id="sidebar-Fee"
        bg-variant="white"
        v-model="sessionbar"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Manage Sessions</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="sessionbar = false"
          />
        </div>

        <b-form class="p-2" @submit.prevent>
          <b-row class="d-flex align-items-center">
            <b-col md="10">
              <b-form-group
                label="Name"
                invalid-feedback="Session name is required."
              >
                <b-form-input
                  ref="sess_name"
                  v-model="sessionObj.session"
                  placeholder="Enter session name here"
                  @focusout="SessName()"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-button
                variant="success"
                class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="saveSession()"
                :disabled="savingacc"
              >
                <b-spinner v-if="savingacc" small type="grow" />
                <feather-icon v-else :icon="accIcon" />
              </b-button>
            </b-col>
          </b-row>
          <b-table
            class="mt-1"
            show-empty
            :items="sessions"
            :fields="sessFields"
            responsive
            hover
            small
          >
            <template #head(actions)="data">
              <div class="text-center">
                <span>{{ data.label }}</span>
              </div>
            </template>

            <template #cell(session)="data">
              <b-badge
                variant="light-primary"
                v-if="data.item.isCurrentSession"
              >
                {{ data.item.session }}
              </b-badge>
              <span v-else>{{ data.item.session }}</span>
            </template>

            <template #cell(actions)="data">
              <div class="text-center">
                <b-button
                  variant="primary"
                  class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                  @click="editSess(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>

                <b-button
                  variant="outline-danger"
                  class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                  @click="deleteSess(data.item)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>

                <b-button
                  v-if="!data.item.isCurrentSession"
                  variant="outline-primary"
                  class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                  @click="setCurrentSess(data.item.id)"
                >
                  <feather-icon icon="CheckCircleIcon" />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-card>
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Campus"
              invalid-feedback="Campus is required."
              ref="camp"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                v-model="campIDs"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="[{ id: 0, branch: 'All Campuses' }, ...campuses]"
                :clearable="false"
                label="branch"
                :reduce="(val) => val.id"
                :disabled="filterLoading"
                placeholder="Select campus"
                @input="setCamp()"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Date"
              invalid-feedback="Date is required."
              ref="dt"
            >
              <flat-pickr
                :config="config2"
                v-model="rangeDate"
                class="form-control"
                placeholder="Select Date."
                @on-change="setLoadDate"
              />
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-button
              class="mt-md-2"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              block
              @click="LoadData(1)"
              :disabled="dataLoading"
            >
              <!-- <feather-icon icon="FilterIcon" class="mr-50" /> -->
              <span class="align-middle">Show</span>
            </b-button>
          </b-col>
          <b-col md="3">
            <b-button
              class="mt-md-2 mt-1"
              @click="confirm()"
              variant="success"
              :disabled="saving || dataLoading || total == 0"
              block
            >
              <b-spinner
                v-if="saving"
                variant="success"
                label="loading"
                small
              ></b-spinner>
              <template v-else>
                <!-- <feather-icon icon="CheckIcon" class="mr-50" /> -->
                <span>Confirm Admission</span>
              </template>
            </b-button>
          </b-col>
        </b-row>

        <b-col
          cols="12"
          class="d-flex align-items-center justify-content-center mt-50"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            first-number
            last-number
            limit="10"
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @input="LoadData(currentPage)"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
        <b-table
          class="mt-1"
          thead-tr-class="adjust-head"
          ref="selectableTable"
          :items="items"
          :fields="fields"
          :busy="dataLoading"
          show-empty
          responsive
          hover
          small
        >
          <template #empty="scope">
            <h3 class="mt-1 mb-1" style="text-align: center">
              No records found
            </h3>
          </template>

          <template #table-busy>
            <div
              class="d-flex justify-content-center mb-3"
              style="margin-top: 50px"
            >
              <b-spinner
                style="width: 3rem; height: 3rem"
                type="grow"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </div>
          </template>

          <template #cell(index)="data">
            <!-- {{ data.index + 1 }} -->
            <b-badge variant="light-primary">
              {{ (currentPage - 1) * perPage + data.index + 1 }}
            </b-badge>
          </template>
          <template #cell(grNo)="data">
            <b-badge variant="light-primary">
              {{ data.item.grNo }}
            </b-badge>
          </template>
          <template #cell(pic)="data">
            <b-img
              v-if="data.item.picture !== null && data.item.picture !== ''"
              :src="data.item.picture"
              height="60px"
              width="60px"
              style="border-radius: 50%; object-fit: contain"
              alt="pic"
            />
            <b-img
              v-else
              src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
              height="60px"
              width="60px"
              style="border-radius: 50%; object-fit: contain"
              alt="pic"
            />
          </template>

          <template #cell(name)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  v-if="logoloading && currentID.id == data.item.id"
                  size="44"
                  variant="light-primary"
                >
                  <b-spinner small />
                </b-avatar>
                <b-avatar
                  v-else-if="data.item.picture"
                  size="44"
                  :src="data.item.picture"
                  variant="light-primary"
                  button
                  :disabled="logoloading"
                  @click.stop="openUpload(data.item)"
                />
                <b-avatar
                  v-else
                  size="44"
                  variant="outline-primary"
                  button
                  :disabled="logoloading"
                  @click.stop="openUpload(data.item)"
                >
                  <feather-icon
                    size="22"
                    icon="UserIcon"
                    class="text-primary"
                  />
                </b-avatar>
                <input
                  type="file"
                  id="fileProfile"
                  hidden
                  ref="fileProfile"
                  @change="selectlogo()"
                  accept="image/*"
                />
              </template>

              <b-link
                class="font-weight-bold d-block text-nowrap"
                style="margin-bottom: 4px"
              >
                <!-- {{ data.item.name }} -->
                <b-badge variant="light-primary">
                  {{ data.item.name }}
                </b-badge>
              </b-link>
              <small class="text-muted">
                <b-badge variant="light-primary" class="mr-50">
                  GR -
                  {{
                    reportDomain === "myskoolhpgs" ||
                    reportDomain === "myskoolprofectus"
                      ? data.item.grNoLocal
                      : data.item.grNo
                  }}
                </b-badge>
                <b-badge variant="light-primary" class="mr-50">
                  F# - {{ data.item.family_code }}
                </b-badge>
                <b-badge variant="light-primary" class="mr-50">
                  RFID: {{ data.item.rfid_number }}
                </b-badge>
              </small>
              <b-link
                class="font-weight-bold d-block text-nowrap"
                style="margin-top: 4px"
              >
                <b-badge variant="light-primary">
                  {{
                    reportDomain === "myskoolhpgs" ||
                    reportDomain === "myskoolprofectus"
                      ? "CID: "
                      : "ID: "
                  }}

                  {{
                    reportDomain === "myskoolhpgs" ||
                    reportDomain === "myskoolprofectus"
                      ? data.item.grNo
                      : data.item.id
                  }}
                </b-badge>
              </b-link>
              <b-link
                v-if="data.item.meta_suspended.match(',')"
                class="font-weight-bold d-block text-nowrap"
                style="margin-top: 4px"
              >
                <b-badge variant="light-danger">
                  {{ data.item.meta_suspended.split(",")[0] }}
                </b-badge>
                |
                <b-badge variant="light-success">
                  {{ data.item.meta_suspended.split(",")[1] }}
                </b-badge>
              </b-link>
            </b-media>
          </template>
          <template #cell(campus)="data">
            <b-badge variant="light-primary">
              {{ getCampus(data.item.campusID) }}
            </b-badge>
          </template>
          <template #cell(admitted_campus)="data">
            <b-badge variant="light-primary">
              {{ getCampus(data.item.campus_Admitted) }}
            </b-badge>
          </template>
          <template #cell(father_name)="data">
            <b-badge variant="light-primary">
              {{ data.item.father_name }}
            </b-badge>
          </template>
          <template #cell(surname)="data">
            <b-badge variant="light-primary">
              {{ data.item.surname }}
            </b-badge>
          </template>
          <template #cell(contact_father)="data">
            <b-badge variant="light-primary">
              {{ data.item.contact_father }}
            </b-badge>
          </template>
          <template #cell(class_current)="data">
            <b-badge variant="light-primary">
              {{ data.item.meta_class }}
            </b-badge>
          </template>
          <template #cell(monthly_fee)="data">
            <b-badge style="margin-inline: 2px" variant="light-primary">
              {{ data.item.monthly_fee }}
            </b-badge>
          </template>
          <template #cell(status)="data">
            <b-badge style="margin-inline: 2px" variant="light-primary">
              {{ data.item.status }}
            </b-badge>
          </template>

          <template #head(actions)="data">
            <div class="text-center">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="d-flex">
              <b-button
                v-if="rights.view"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mr-1"
                v-b-tooltip.hover.top
                title="View"
                :to="{
                  name: 'apps-users-view',
                  params: {
                    id: data.item.id,
                    item: { ...data.item },
                  },
                }"
              >
                <!-- @click="View(data.item)" -->
                <feather-icon icon="EyeIcon" />
              </b-button>
              <!-- <b-button
                v-if="rights.edit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mr-1"
                v-b-tooltip.hover.top
                title="Edit"
                :to="{
                  name: 'manage-student',
                  params: {
                    id: data.item.id,
                    readmit: false,
                  },
                }"
              >
              <feather-icon icon="EditIcon" class="" />
            </b-button> -->
              <!-- @click="Edit(data.item.id, false)" -->
              <!-- 
              <b-button
                v-if="rights.editCampus"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon mr-1"
                v-b-tooltip.hover.top
                title="Transfer Student"
                @click="openCh(data.item)"
              >
                <feather-icon icon="HomeIcon" />
              </b-button>

              <b-button
                v-if="['Registered', 'registered'].includes(data.item.status)"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon mr-1"
                v-b-tooltip.hover.top
                title="Print"
                @click="printAdm(data.item)"
              >
                <feather-icon icon="PrinterIcon" />
              </b-button>

              <b-button
                v-if="rights.delete"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                class="btn-icon mr-1"
                v-b-tooltip.hover.top
                title="Delete"
                @click="Delete(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>

              <b-button
                v-if="
                  rights.edit &&
                  data.item.status !== 'left' &&
                  rights.edit &&
                  data.item.status !== 'Left'
                "
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                class="btn-icon mr-1"
                v-b-tooltip.hover.top
                title="Left"
                @click="Leave(data.item)"
              >
                <feather-icon icon="InfoIcon" />
              </b-button>
              <b-button
                v-if="
                  (rights.edit && data.item.status == 'left') ||
                  (rights.edit && data.item.status == 'Left')
                "
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon mr-1"
                v-b-tooltip.hover.top
                title="Readmit"
                :to="{
                  name: 'manage-student',
                  params: {
                    id: data.item.id,
                    readmit: true,
                  },
                }"
              >

                <feather-icon icon="CheckIcon" />
              </b-button>
              <b-button
                v-if="
                  (rights.edit && data.item.status == 'Registered') ||
                  (rights.edit && data.item.status == 'registered')
                "
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon mr-1"
                v-b-tooltip.hover.top
                title="Confirm Admission"
                :to="{
                  name: 'manage-student',
                  params: {
                    id: data.item.id,
                    readmit: true,
                  },
                }"
              >
                <feather-icon icon="CheckCircleIcon" />
              </b-button>
              <b-button
                v-if="
                  (rights.edit && data.item.status == 'present') ||
                  (rights.edit && data.item.status == 'Present')
                "
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                class="btn-icon mr-1"
                v-b-tooltip.hover.top
                title="Suspend"
                @click="openSus(data.item)"
              >
                <feather-icon icon="XIcon" />
              </b-button> -->
            </div>
          </template>
          <!-- <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template> -->
        </b-table>
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ items.length }} of
              {{ total == 0 ? items.length : total }} entries</span
            >
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  // BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BContainer,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import readXlsxFile from "read-excel-file";
import store from "@/store";
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";
import { TheMask } from "vue-the-mask";
import { mapActions, mapGetters } from "vuex";

import * as XLSX from "xlsx";

export default {
  components: {
    //Multiselect,
    TheMask,
    BInputGroup,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    vSelect,
    BAvatar,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BSpinner,
    BFormCheckbox,
    BForm,
    BContainer,
    //BCardTitle,
    //BCardText,
    BMediaAside,
    BImg,
    BMedia,
    //BLink,
    // BSpinner,
    //BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    // vSelect,
    flatPickr,
    //'gallery': VueGallery
  },
  props: {
    /* modalFilter: {
    type: [String, null],
    default: null,
    },
    modalFilterOptions: {
    type: Array,
    required: true,
    }*/
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
          hideFee: ch.hideFee,
          editCampus: ch.editCampus,
        };
      });
    });
    // console.log(right);
    this.$store.commit("setRights", right);
    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      // console.log(this.rights);
      this.LoadData(1);
      // this.LoadFilter();
      this.LoadCampuses();
    }
    // this.LoadData(1);
    // this.LoadCampuses();
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),

    filters: function () {
      return this.items.filter((pro) => {
        return pro.name.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      rights: {},
      filterloading: false,
      filter: {
        cls: 0,
        sec: 0,
        gender: null,
        status: "present",
      },
      regFilter: {
        dateType: "admission",
        dtFrom: null,
        dtTill: null,
      },
      dtOptions: [
        { text: "Admission Test", value: "admission" },
        { text: "Interview", value: "interview" },
      ],
      filterDate: null,
      config2: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        mode: "range",
      },
      byFilter: false,
      genders: ["Male", "Female"],
      classes: [],
      contacterror: false,
      mydocloading: false,
      docsloading: "Upload",
      request: false,
      colorDep: [],
      visibility2: false,
      visibility3: false,
      color: [
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
      ],

      sidebarTitle: "Leave Student",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,
      departments: [],
      visibility: false,
      logoloading: false,
      currentID: {},
      searchQuery: "",
      currentPage: 1,
      perPage: 50,
      totalRows: 0,
      errors: {
        status: false,
      },
      statusOptions: [
        { text: "Present", value: "present" },
        { text: "Registered", value: "registered" },
        // { text: "Enrolled", value: "enrolled" },
        { text: "Left", value: "left" },
      ],
      filterStatusOpt: [
        { text: "Present", value: "present" },
        { text: "Registered", value: "registered" },
      ],
      currentStatus: "present",
      filterData: this.$store.state.stdFilter,
      date: {
        range: "",
      },
      index: null,
      cover: "",
      docs: [],
      partners: [],
      fields: [
        { label: "S.No", key: "index" },
        // { label: "GR No", key: "grNo" },
        // { label: "picture", key: "pic" },
        { label: "name", key: "name" },
        { label: "father name", key: "father_name" },
        { label: "campus", key: "campus" },
        { label: "admitted campus", key: "admitted_campus" },
        { label: "contact", key: "contact_father" },
        { label: "class", key: "class_current" },
        { label: "Fees", key: "monthly_fee" },
        { label: "status", key: "status" },
        "actions",
      ],
      otherFields: [
        { label: "S.No", key: "index" },
        // { label: "GR No", key: "grNo" },
        // { label: "picture", key: "pic" },
        { label: "name", key: "name" },
        { label: "father name", key: "father_name" },
        { label: "campus", key: "campus" },
        { label: "Surname", key: "surname" },
        { label: "contact", key: "contact_father" },
        { label: "class", key: "class_current" },
        { label: "Fees", key: "monthly_fee" },
        { label: "status", key: "status" },
        "actions",
      ],
      surnameVisible: ["myskool_concept_thatta", "myskool_perfect_thatta"],
      currentSections: [{ section: "All Sections", id: 0 }],
      items: [],
      total: null,
      selected: null,
      myObj: {
        id: 0,
        name: "",
        father_name: "",
        contact: "",
        email: "",
        address: "",
        purpose: "",
        tag: "",
        date: "0001-01-01T00:00:00",
        campusID: this.$store.state.userData.cId,
        attachments: [],
      },
      leaveObj: {},
      susObj: {
        sID: 0,
        suspend_till: null,
        suspend_reason: "",
        suspend_for_attentance: false,
        suspend_for_homework: false,
        suspend_for_exams: false,
        suspend_for_messaging: false,
      },
      currentSelected: "",
      susOptions: [
        { text: "1 Week", value: "week" },
        { text: "1 Month", value: "month" },
        { text: "1 Year", value: "year" },
      ],
      visibility5: false,
      totalDues: 0,
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      depObj: {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      },
      classesOptions: [],
      detailObj: {},
      avatarURL:
        "https://raw.githubusercontent.com/Ashwinvalento/cartoon-avatar/master/lib/images/female/110.png",
      feeImporting: false,
      stdImporting: false,
      staffImporting: false,
      feeData: [],
      stdData: [],
      staffData: [],
      codeBy: false,
      syncLoading: false,
      assignLoading: false,
      exporting: false,
      printing: false,
      stdIDs: [],
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      sessionID: null,
      sessionName: "",
      sessions: [],
      certOptions: [
        { name: "ID Card", value: "id-card" },
        { name: "Gate Pass", value: "gatepass" },
        { name: "Admission Form", value: "admission-form" },
        { name: "Transfer Certificate", value: "transfer" },
        { name: "Character Certificate", value: "character" },
        { name: "Provisional Certificate", value: "provisional" },
        // { name: "Leaving Certificate", value: "leaving" },
      ],
      searchType: this.$store.state.stdSearch,
      searchOptions: [
        { name: "General", value: "" },
        { name: "GR", value: "gr" },
        { name: "Name", value: "name" },
        { name: "Father Name", value: "father name" },
        { name: "Family Code", value: "family code" },
        { name: "Contact", value: "contact" },
        { name: "Surname", value: "surname" },
        { name: "CNIC", value: "cnic" },
      ],
      reportObj: {
        type: "",
      },
      reportPrinting: false,
      exportSchema: [],
      exportSelected: [],
      visibility4: false,
      accIcon: "",
      sessionbar: false,
      sessionObj: {
        id: 0,
        session: "",
        campusID: this.$store.state.userData.cId,
      },
      sessFields: [
        { label: "Name", key: "session" },
        { key: "actions", label: "actions" },
      ],
      savingacc: false,
      saving: false,
      campuses: [],
      campIDs: [0],
      loadObj: {
        campusIDs: [],
        dtFrom: new Date().toJSON().split("T")[0],
        dtTo: new Date().toJSON().split("T")[0],
      },
      confirmObj: {},
      rangeDate: `${new Date().toJSON().split("T")[0]} to ${
        new Date().toJSON().split("T")[0]
      }`,
      chObj: {},
      visibility7: false,
      chOptions: [],
      chClasses: [],
      chSections: [],
      clsLoading: false,
    };
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    setCamp() {
      if (this.campIDs.at(-1) == 0) {
        this.campIDs = [0];
      } else {
        this.campIDs = this.campIDs.filter((el) => el != 0);
      }
    },
    setLoadDate(sd, dateStr) {
      // console.log(dateStr);
      if (dateStr) {
        let dt = dateStr.split(" to ");
        if (dt.length == 1) {
          this.loadObj.dtFrom = dt[0];
          this.loadObj.dtTo = dt[0];
        } else {
          this.loadObj.dtFrom = dt[0];
          this.loadObj.dtTo = dt[1];
        }
      } else {
        this.rangeDate = null;
        this.loadObj.dtFrom = null;
        this.loadObj.dtTo = null;
      }
      // console.log(this.rangeDate);
    },
    async confirm() {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to confirm the admissions? You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes",
      });
      if (result.isConfirmed) {
        // console.log(this.confirmObj);
        this.saving = true;

        var status = await this.post({
          url:
            this.$store.state.domain +
            "Students/ConfirmAdmissionMultiple?db=" +
            this.$store.state.userData.db,
          body: this.confirmObj,
          context: this,
          message: "Admission Confirmed successfully!",
          token: this.$store.state.userData.token,
        });
        this.saving = false;
        if (status) this.LoadData(1);
      }
    },
    openCh(item) {
      this.chObj = {
        ...item,
        newCampus: 0,
        clsID: 0,
        secID: 0,
      };

      this.visibility7 = true;
      this.chSections = [];
      this.chClasses = [];
      this.chOptions = [];
    },
    async LoadClass() {
      this.checkChCamp();
      this.clsLoading = true;
      this.chObj.clsID = 0;
      this.chObj.secID = 0;
      this.chSections = [];

      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.chObj.newCampus +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      //  this.items =[];
      let myitem = await this.get(obj);
      this.chClasses = myitem;
      this.chOptions = this.chClasses.map((el) => el.cls);

      this.clsLoading = false;
    },
    FillChSec() {
      this.chObj.secID = 0;
      this.chSections = [];
      let obj = this.chClasses.find((el) => el.cls.id == this.chObj.clsID);
      if (obj && obj.sections.length > 0) {
        this.chSections = obj.sections;
      } else this.chSections = [];

      this.checkChClass();
    },
    checkChCamp() {
      var elem = this.$refs["ch_camp"];
      if (this.chObj.newCampus == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkChClass() {
      var elem = this.$refs["ch_cls"];
      if (this.chObj.clsID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkChSec() {
      var elem = this.$refs["ch_sec"];
      if (this.chObj.secID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveCh() {
      this.checkChCamp();
      this.checkChClass();
      this.checkChSec();
      if (
        this.checkChCamp() == false ||
        this.checkChClass() == false ||
        this.checkChSec() == false
      ) {
        this.$bvToast.toast("Please select all the required fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
      // else if (this.chObj.campusID == this.chObj.newCampus) {
      //   this.$bvToast.toast("Current campus and transfer campus are same", {
      //     title: "Error!",
      //     variant: "danger",
      //     toaster: "b-toaster-top-center",
      //   });
      // }
      else {
        let result = await Swal.fire({
          title: "Are you sure?",
          text: "Do you want to transfer the student?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes!",
        });

        if (result.isConfirmed) {
          this.request = true;
          let data = {
            sID: this.chObj.id,
            previousCampus: this.chObj.campusID,
            newCampus: this.chObj.newCampus,
            clsID: this.chObj.clsID,
            secID: this.chObj.secID,
            userID: this.$store.state.userData.userID,
          };
          // console.log(data);
          let status = await this.post({
            url:
              this.$store.state.domain +
              "Students/TransferCampus?db=" +
              this.$store.state.userData.db,
            body: data,
            message: "Student transferred successfully!",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.visibility7 = false;
            if (this.byFilter) this.Filter(this.currentPage);
            else this.LoadData();
          }
        }
      }
    },

    setDate(sd, dateStr) {
      if (dateStr) {
        let dt = dateStr.split(" to ");
        if (dt.length == 1) {
          this.regFilter.dtFrom = dt[0];
          this.regFilter.dtTill = dt[0];
        } else {
          this.regFilter.dtFrom = dt[0];
          this.regFilter.dtTill = dt[1];
        }
      } else {
        this.filterDate = null;
        this.regFilter.dtFrom = null;
        this.regFilter.dtTill = null;
      }
      // console.log(this.regFilter.dtFrom, this.regFilter.dtTill);
    },
    getCampus(id) {
      let obj = this.campuses.find((el) => el.id == id);
      return obj ? obj.branch : "";
    },
    openUpload(item) {
      this.currentID = item;
      // console.log(item);
      this.$refs.fileProfile.click();
    },
    selectlogo() {
      var axios = require("axios");
      if (this.$refs.fileProfile.files[0] !== "") {
        this.logoloading = true;

        let formData = new FormData();
        formData.append("file", this.$refs.fileProfile.files[0]);
        axios
          .post("https://upload.appick.io/profile-picture", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            // var fn1 = e.data.myresp[0].path;
            var fn1 = e.data.file.path;
            this.currentID.picture = fn1;
            this.updateStd();
          })
          .catch((err) => {
            // console.log(err);
            this.currentID = {};
            this.logoloading = false;
          });
      }
    },
    async updateStd() {
      let status = await this.put({
        url:
          this.$store.state.domain +
          "Students/UpdatePicture?db=" +
          this.$store.state.userData.db +
          "&sID=" +
          this.currentID.id +
          "&picture=" +
          this.currentID.picture,
        message: "Picture updated successfully!",
        context: this,
        token: this.$store.state.userData.token,
      });
      this.logoloading = false;
      this.currentID = {};
    },

    async LoadCampuses() {
      this.filterloading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "campuses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.campuses = await this.get(obj);
      this.filterloading = false;
    },

    async loadSessions() {
      var obj = {
        url:
          this.$store.state.domain +
          "Sessions?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.sessions = await this.get(obj);
      let sess = this.sessions.find((el) => el.isCurrentSession);
      if (sess) {
        this.sessionName = sess.session;
        this.sessionID = sess.id;
      }
    },
    openSession() {
      this.accIcon = "PlusIcon";
      this.sessionbar = true;

      this.sessionObj = {
        id: 0,
        session: "",
        campusID: this.$store.state.userData.cId,
      };
      var elem = this.$refs["sess_name"];
      elem.state = undefined;
    },
    async editSess(item) {
      this.sessionObj = { ...item };
      this.accIcon = "Edit2Icon";
      var elem = this.$refs["sess_name"];
      elem.state = undefined;
      // console.log(this.sessionObj);
    },
    SessName() {
      var elem = this.$refs["sess_name"];
      if (this.sessionObj.session.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async setCurrentSess(id) {
      var status = await this.post({
        url:
          this.$store.state.domain +
          "Sessions/SetCurrentSession?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&sessionID=" +
          id,
        body: this.sessionObj,
        message: "Session marked as current",
        context: this,
        token: this.$store.state.userData.token,
      });

      if (status) {
        this.loadSessions();
      }
    },
    async saveSession() {
      if (this.SessName() == true) {
        this.savingacc = true;
        if (this.sessionObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Sessions?db=" +
              this.$store.state.userData.db,
            body: this.sessionObj,
            message: "Session added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              "Sessions/" +
              this.sessionObj.id +
              "?db=" +
              this.$store.state.userData.db,
            message: "Session updated successfully.",
            context: this,
            body: this.sessionObj,
            token: this.$store.state.userData.token,
          });
        }
        this.savingacc = false;
        if (status) {
          this.loadSessions();
          var elem = this.$refs["sess_name"];
          elem.state = undefined;
          this.sessionObj = {
            id: 0,
            session: "",
            campusID: this.$store.state.userData.cId,
          };
          this.accIcon = "PlusIcon";
        }
      }
    },
    async deleteSess(item) {
      // console.log(acc);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "Sessions/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Session removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadSessions();
      }
    },

    openReport(id) {
      if (this.stdIDs.length > 0) {
        this.reportObj = {
          type: "",
        };
        this.$bvModal.show("modal-cert");
      } else {
        this.$bvToast.toast("Please select students first.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },
    checkRepType() {
      var elem = this.$refs["type"];
      if (this.reportObj.type == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    printRep() {
      if (this.checkRepType() == true) {
        this.reportPrinting = true;

        if (this.reportObj.type == "id-card") {
          var url =
            `https://${this.reportDomain}.myskool.app/Certificate/Show?type=id-card&dbb=` +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&sID=" +
            this.stdIDs +
            "&session=" +
            this.sessionName;
          window.open(url, "_blank");
        } else {
          var url =
            `https://${this.reportDomain}.myskool.app/Certificate/Show?type=${this.reportObj.type}&dbb=` +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&sID=" +
            this.stdIDs +
            "&cls=0&sec=0";
          window.open(url, "_blank");
        }
        this.reportPrinting = false;
        this.$bvModal.hide("modal-cert");
      }
    },
    printAdm(item) {
      var url =
        `https://${this.reportDomain}.myskool.app/Certificate/Show?type=admission-form&dbb=` +
        this.$store.state.userData.db +
        "&cID=" +
        item.campusID +
        "&sID=" +
        item.id +
        "&cls=0&sec=0";
      window.open(url, "_blank");
    },

    stdSelect(row) {
      this.stdIDs = row.reduce((acc, el) => {
        acc.push(el.id);
        return acc;
      }, []);

      // console.log(this.stdIDs);
    },

    printIDs() {
      if (this.stdIDs.length > 0) {
        this.printing = true;
        var url =
          `https://${this.reportDomain}.myskool.app/Certificate/Show?type=id-card&dbb=` +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&sID=" +
          this.stdIDs +
          "&session=" +
          this.sessionName;
        window.open(url, "_blank");
        this.printing = false;
      } else {
        this.$bvToast.toast("Please select students first.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },

    unselect() {
      this.$refs.selectableTable.clearSelected();
      // this.stdIDs = [];
    },

    async syncApp() {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "This will syncronize mobile accounts.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Sync it!",
      });

      if (result.isConfirmed) {
        this.syncLoading = true;
        var status = this.get({
          url:
            this.$store.state.domain +
            "Students/SyncAll?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          message: "Accounts synced successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });

        if (status) this.syncLoading = false;
      }
    },

    async assignCode() {
      console.log(this.codeBy);
      this.assignLoading = true;
      var status = this.post({
        url:
          this.$store.state.domain +
          "Students/GenerateFamilyCode?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&mob=" +
          this.codeBy,
        body: null,
        message: "Family code assigned successfully!",
        context: this,
        token: this.$store.state.userData.token,
      });

      if (status) {
        this.assignLoading = false;
        this.visibility3 = false;
      }

      // let result = await Swal.fire({
      //   title: "Are you sure?",
      //   text: "This will auto assign family code using father's CNIC.",
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonColor: "#d33",
      //   cancelButtonColor: "#3085d6",
      //   confirmButtonText: "Yes, Assign it!",
      // });

      // if (result.isConfirmed) {

      // }
    },
    openExport() {
      this.exportSchema = [
        {
          text: "ID",
          value: "id",
          status: false,
        },
        {
          text: "grNo",
          value: "grNo",
          status: true,
        },
        {
          text: "Fcode",
          value: "family_code",
          status: true,
        },
        {
          text: "name",
          value: "name",
          status: true,
        },
        {
          text: "father_name",
          value: "father_name",
          status: false,
        },
        {
          text: "mother_name",
          value: "mother_name",
          status: false,
        },
        {
          text: "gender",
          value: "gender",
          status: false,
        },
        {
          text: "date_of_birth",
          value: "date_of_birth",
          status: false,
        },
        {
          text: "surname",
          value: "surname",
          status: false,
        },
        {
          text: "contact",
          value: "contact_father",
          status: false,
        },
        {
          text: "contact_mother",
          value: "contact_mother",
          status: false,
        },
        {
          text: "contact_student",
          value: "contact_student",
          status: false,
        },
        {
          text: "address",
          value: "address_current",
          status: false,
        },
        {
          text: "rollNo",
          value: "roll_number",
          status: false,
        },
        {
          text: "date_of_admission",
          value: "date_of_admission",
          status: false,
        },
        {
          text: "last_institute",
          value: "last_institute",
          status: false,
        },
        {
          text: "class",
          value: "meta_class",
          status: false,
        },
        {
          text: "class_admitted",
          value: "class_admitted",
          status: false,
        },
        {
          text: "fees",
          value: "monthly_fee",
          status: false,
        },
        {
          text: "status",
          value: "status",
          status: false,
        },
        {
          text: "house",
          value: "house",
          status: false,
        },
        {
          text: "father_occupation",
          value: "father_occupation",
          status: false,
        },
        {
          text: "father_cnic",
          value: "father_cnic",
          status: false,
        },
        {
          text: "father_education",
          value: "father_education",
          status: false,
        },
        {
          text: "father_office_phone",
          value: "office_phone",
          status: false,
        },
        {
          text: "father_office_address",
          value: "office_address",
          status: false,
        },
        {
          text: "mother_occupation",
          value: "mother_occupation",
          status: false,
        },
        {
          text: "mother_cnic",
          value: "mother_cnic",
          status: false,
        },
        {
          text: "mother_education",
          value: "mother_education",
          status: false,
        },
        {
          text: "mother_office_phone",
          value: "mother_office_phone",
          status: false,
        },
        {
          text: "mother_office_address",
          value: "mother_office_address",
          status: false,
        },
        {
          text: "guardian_name",
          value: "guardian_name",
          status: false,
        },
        {
          text: "guardian_cnic",
          value: "guardian_cnic",
          status: false,
        },
        {
          text: "rfID",
          value: "rfid_number",
          status: false,
        },
        {
          text: "date_of_leaving",
          value: "date_of_leaving",
          status: false,
        },
      ];

      // console.log(this.exportSchema);
      this.visibility4 = true;
    },
    async exportNew() {
      let toShow = this.exportSchema.filter((el) => el.status == true);
      // console.log(toShow);

      if (toShow.length > 0) {
        this.exporting = true;
        const count = this.total < 50 ? 1 : Math.ceil(this.total / 50);
        let arr = [];
        if (this.byFilter) {
          for (let i = 1; i <= count; i++) {
            let result = await this.get({
              url:
                this.$store.state.domain +
                "students/Filterdata?cls=" +
                this.filter.cls +
                "&sec=" +
                this.filter.sec +
                "&status=" +
                this.filter.status +
                "&db=" +
                this.$store.state.userData.db +
                "&cId=" +
                this.$store.state.userData.cId +
                "&page=" +
                i,
              token: this.$store.state.userData.token,
            });
            arr = [...arr, ...result.data];
          }
        } else {
          for (let i = 1; i <= count; i++) {
            let res = await this.get({
              url:
                this.$store.state.domain +
                "Students/LoadDataPagination?db=" +
                this.$store.state.userData.db +
                "&cID=" +
                this.$store.state.userData.cId +
                "&clsID=0&section=0&page=" +
                i +
                "&status=" +
                this.currentStatus,
              token: this.$store.state.userData.token,
            });
            arr = [...arr, ...res.data];
          }
        }
        // console.log(arr);
        const expData = arr.map((item) => {
          let fd = {};
          toShow.forEach((elem) => {
            let d;
            if (elem.text == "class_admitted") {
              let obj = this.classesOptions.find(
                (el) => el.id == item[elem.value]
              );
              d = { [elem.text]: obj ? obj.name : "" };
            } else {
              d = { [elem.text]: item[elem.value] };
            }
            Object.assign(fd, d);
          });
          return fd;
        });
        // console.log(expData);

        const worksheet = XLSX.utils.json_to_sheet(expData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
        XLSX.writeFile(workbook, "students.xlsx");
        this.exporting = false;
      } else {
        this.$bvToast.toast("Please select the field to export", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },

    async exportData() {
      this.exporting = true;

      const count = this.total < 50 ? 1 : Math.ceil(this.total / 50);
      let arr = [];

      if (this.byFilter) {
        for (let i = 1; i <= count; i++) {
          let result = await this.get({
            url:
              this.$store.state.domain +
              "students/Filterdata?cls=" +
              this.filter.cls +
              "&sec=" +
              this.filter.sec +
              "&status=" +
              this.filter.status +
              "&db=" +
              this.$store.state.userData.db +
              "&cId=" +
              this.$store.state.userData.cId +
              "&page=" +
              i,
            token: this.$store.state.userData.token,
          });
          arr = [...arr, ...result.data];
        }
      } else {
        for (let i = 1; i <= count; i++) {
          let res = await this.get({
            url:
              this.$store.state.domain +
              "Students/LoadDataPagination?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId +
              "&clsID=0&section=0&page=" +
              i +
              "&status=" +
              this.currentStatus,
            token: this.$store.state.userData.token,
          });
          arr = [...arr, ...res.data];
        }
      }
      // console.log(arr);
      const expData = arr.map((item) => ({
        gr: item.grNo,
        family_code: item.family_code,
        name: item.name,
        father: item.father_name,
        surname: item.surname,
        gender: item.gender,
        contact: item.contact_father,
        contact_mother: item.contact_mother,
        contact_student: item.contact_student,
        current_address: item.address_current,
        class: item.meta_class,
        fees: item.monthly_fee,
        status: item.status,
        house: item.house,
        rfID: item.rfid_number,
      }));

      const worksheet = XLSX.utils.json_to_sheet(expData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
      XLSX.writeFile(workbook, "students.xlsx");

      this.exporting = false;
    },

    async Import() {
      this.stdImporting = true;
      const schema = {
        id: {
          prop: "id",
          type: Number,
        },
        grNo: {
          prop: "grNo",
          type: String,
        },
        family_code: {
          prop: "family_code",
          type: Number,
        },
        name: {
          prop: "name",
          type: String,
        },
        father_name: {
          prop: "father_name",
          type: String,
        },
        gender: {
          prop: "gender",
          type: String,
        },
        picture: {
          prop: "picture",
          type: String,
        },
        date_of_birth: {
          prop: "date_of_birth",
          type: Date,
        },
        date_of_birth_words: {
          prop: "date_of_birth_words",
          type: String,
        },
        blood_group: {
          prop: "blood_group",
          type: String,
        },
        nationality: {
          prop: "nationality",
          type: String,
        },
        religion: {
          prop: "religion",
          type: String,
        },
        place_of_birth: {
          prop: "place_of_birth",
          type: String,
        },
        mother_tongue: {
          prop: "mother_tongue",
          type: String,
        },
        address_current: {
          prop: "address_current",
          type: String,
        },
        address_permanent: {
          prop: "address_permanent",
          type: String,
        },
        contact_student: {
          prop: "contact_student",
          type: String,
        },
        contact_father: {
          prop: "contact_father",
          type: String,
        },
        contact_mother: {
          prop: "contact_mother",
          type: String,
        },
        contact_home: {
          prop: "contact_home",
          type: String,
        },
        contact_tutor: {
          prop: "contact_tutor",
          type: String,
        },
        last_institute: {
          prop: "last_institute",
          type: String,
        },
        last_class: {
          prop: "last_class",
          type: String,
        },
        last_session: {
          prop: "last_session",
          type: String,
        },
        last_result: {
          prop: "last_result",
          type: String,
        },
        last_note: {
          prop: "last_note",
          type: String,
        },
        class_admitted: {
          prop: "class_admitted",
          type: String,
        },
        class_current: {
          prop: "class_current",
          type: String,
        },
        section_admitted: {
          prop: "section_admitted",
          type: String,
        },
        section_current: {
          prop: "section_current",
          type: String,
        },
        rollNumber: {
          prop: "rollNumber",
          type: Number,
        },
        house: {
          prop: "house",
          type: String,
        },
        rfid_number: {
          prop: "rfid_number",
          type: String,
        },
        bform_number: {
          prop: "bform_number",
          type: String,
        },
        date_of_registration: {
          prop: "date_of_registration",
          type: Date,
        },
        date_of_readmission: {
          prop: "date_of_readmission",
          type: Date,
        },
        father_cnic: {
          prop: "father_cnic",
          type: String,
        },
        father_education: {
          prop: "father_education",
          type: String,
        },
        father_occupation: {
          prop: "father_occupation",
          type: String,
        },
        monthly_income: {
          prop: "monthly_income",
          type: String,
        },
        office_phone: {
          prop: "office_phone",
          type: String,
        },
        office_address: {
          prop: "office_address",
          type: String,
        },
        father_email: {
          prop: "father_email",
          type: String,
        },
        father_employer: {
          prop: "father_employer",
          type: String,
        },
        mother_name: {
          prop: "mother_name",
          type: String,
        },
        mother_cnic: {
          prop: "mother_cnic",
          type: String,
        },
        mother_education: {
          prop: "mother_education",
          type: String,
        },
        mother_occupation: {
          prop: "mother_occupation",
          type: String,
        },
        mother_office_phone: {
          prop: "mother_office_phone",
          type: String,
        },
        mother_office_address: {
          prop: "mother_office_address",
          type: String,
        },
        mother_email: {
          prop: "mother_email",
          type: String,
        },
        mother_employer: {
          prop: "mother_employer",
          type: String,
        },
        guardian_name: {
          prop: "guardian_name",
          type: String,
        },
        guardian_cnic: {
          prop: "guardian_cnic",
          type: String,
        },
        guardian_education: {
          prop: "guardian_education",
          type: String,
        },
        guardian_occupation: {
          prop: "guardian_occupation",
          type: String,
        },
        guardian_office_phone: {
          prop: "guardian_office_phone",
          type: String,
        },
        guardian_office_address: {
          prop: "guardian_office_address",
          type: String,
        },
        guardian_email: {
          prop: "guardian_email",
          type: String,
        },
        guardian_employer: {
          prop: "guardian_employer",
          type: String,
        },
        guardian_relationship: {
          prop: "guardian_relationship",
          type: String,
        },
        emergency_name: {
          prop: "emergency_name",
          type: String,
        },
        emergency_contact: {
          prop: "emergency_contact",
          type: String,
        },
        emergency_relationship: {
          prop: "emergency_relationship",
          type: String,
        },
        physical_handicap: {
          prop: "physical_handicap",
          type: String,
        },
        allergies: {
          prop: "allergies",
          type: String,
        },
        remarks: {
          prop: "remarks",
          type: String,
        },
        monthly_fee: {
          prop: "monthly_fee",
          type: Number,
        },
        discount_percentage: {
          prop: "discount_percentage",
          type: Number,
        },
        challan_due_date: {
          prop: "challan_due_date",
          type: Date,
        },
        campusID: {
          prop: "campusID",
          type: Number,
        },
        session: {
          prop: "session",
          type: String,
        },
        status: {
          prop: "status",
          type: String,
        },
        docs: {
          prop: "docs",
          type: String,
        },
        date_of_admission: {
          prop: "date_of_admission",
          type: Date,
        },
      };
      // console.log("import....");
      const input = document.getElementById("importFile");
      // console.log(input.files);
      await readXlsxFile(input.files[0], { schema })
        .then((rows) => {
          // `rows` is an array of rows
          this.stdData = rows.rows;
        })
        .catch((err) => {
          console.log(err);
          this.stdImporting = false;
        });
      // console.log(this.stdData);
      const chunkSize = 10000;
      let chunkLength = 0;

      for (let i = 0; i < this.stdData.length; i += chunkSize) {
        const chunk = this.stdData.slice(i, i + chunkSize);
        chunkLength += chunk.length;

        // console.log(chunk);
        var status = this.post({
          url:
            this.$store.state.domain +
            "Students/SaveAll?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: chunk,
          message: "Students data imported.",
          importing: true,
          lastChunk: chunkLength == this.stdData.length,
          context: this,
          token: this.$store.state.userData.token,
        });

        if (!status) this.stdImporting = false;
        else if (chunkLength == this.stdData.length) this.stdImporting = false;
      }

      // input.addEventListener("change", () => {

      // });
    },
    async ImportFee() {
      this.feeImporting = true;
      const schema = {
        id: {
          prop: "id",
          type: Number,
        },
        sID: {
          prop: "sID",
          type: Number,
        },
        billID: {
          prop: "billID",
          type: Number,
        },
        classID: {
          prop: "classID",
          type: String,
        },
        secID: {
          prop: "secID",
          type: String,
        },
        month: {
          prop: "month",
          type: String,
        },
        year: {
          prop: "year",
          type: Number,
        },
        generatedOn: {
          prop: "generatedOn",
          type: Date,
        },
        feesType: {
          prop: "feesType",
          type: String,
        },
        actualFee: {
          prop: "actualFee",
          type: Number,
        },
        discount: {
          prop: "discount",
          type: Number,
        },
        netFee: {
          prop: "netFee",
          type: Number,
        },
        status: {
          prop: "status",
          type: String,
        },
        rcvd: {
          prop: "rcvd",
          type: Number,
        },
        balance: {
          prop: "balance",
          type: Number,
        },
        rcvdDate: {
          prop: "rcvdDate",
          type: Date,
        },
        createdBy: {
          prop: "createdBy",
          type: Number,
        },
        modificationTime: {
          prop: "modificationTime",
          type: Date,
        },
        barcode: {
          prop: "barcode",
          type: Number,
        },
        monthYear: {
          prop: "monthYear",
          type: String,
        },
        accountID: {
          prop: "accountID",
          type: Number,
        },
        isDeleted: {
          prop: "isDeleted",
          type: Boolean,
        },
        deletedOn: {
          prop: "deletedOn",
          type: Date,
        },
        deletedBy: {
          prop: "deletedBy",
          type: Number,
        },
        timelyDiscount: {
          prop: "timelyDiscount",
          type: Number,
        },
        lateFee: {
          prop: "lateFee",
          type: Number,
        },
        campusID: {
          prop: "campusID",
          type: Number,
        },
        monthVal: {
          prop: "monthVal",
          type: String,
        },
        rcvdBy: {
          prop: "rcvdBy",
          type: Number,
        },
      };
      // console.log("import fee....");
      const input = document.getElementById("importFee");
      // console.log(input.files);
      await readXlsxFile(input.files[0], { schema })
        .then((rows) => {
          // `rows` is an array of rows
          this.feeData = rows.rows;
        })
        .catch((err) => {
          console.log(err);
          this.feeImporting = false;
        });
      // console.log(this.feeData);
      const chunkSize = 4000;
      let chunkLength = 0;

      for (let i = 0; i < this.feeData.length; i += chunkSize) {
        const chunk = this.feeData.slice(i, i + chunkSize);
        chunkLength += chunk.length;

        // console.log(chunk);
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Fees/SaveAll?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: chunk,
          message: "Fee data imported.",
          importing: true,
          lastChunk: chunkLength == this.feeData.length,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (!status) this.feeImporting = false;
        else if (chunkLength == this.feeData.length) this.feeImporting = false;
      }
      // input.addEventListener("change", () => {

      // });
    },

    async ImportStaff() {
      this.staffImporting = true;
      const schema = {
        id: {
          prop: "id",
          type: Number,
        },
        rfID: {
          prop: "rfID",
          type: Number,
        },
        name: {
          prop: "name",
          type: String,
        },
        father_name: {
          prop: "father_name",
          type: String,
        },
        designation: {
          prop: "designation",
          type: String,
        },
        gender: {
          prop: "gender",
          type: String,
        },
        picture: {
          prop: "picture",
          type: String,
        },
        date_of_birth: {
          prop: "date_of_birth",
          type: Date,
        },
        address_current: {
          prop: "address_current",
          type: String,
        },
        address_permanent: {
          prop: "address_permanent",
          type: String,
        },
        contact_number: {
          prop: "contact_number",
          type: String,
        },
        emergency_contact_person: {
          prop: "emergency_contact_person",
          type: String,
        },
        emergency_contact_relation: {
          prop: "emergency_contact_relation",
          type: String,
        },
        emergency_number: {
          prop: "emergency_number",
          type: String,
        },
        qualification: {
          prop: "qualification",
          type: String,
        },
        experience: {
          prop: "experience",
          type: String,
        },
        campusID: {
          prop: "campusID",
          type: Number,
        },
        canPostNewsFeed: {
          prop: "canPostNewsFeed",
          type: Boolean,
        },
        totalPeriods: {
          prop: "totalPeriods",
          type: Number,
        },
        status: {
          prop: "status",
          type: String,
        },
        deviceID: {
          prop: "deviceID",
          type: String,
        },
        departmentID: {
          prop: "departmentID",
          type: Number,
        },
        availibility: {
          prop: "availibility",
          type: String,
        },
        documents: {
          prop: "documents",
          type: String,
        },
        expectedSalary: {
          prop: "expectedSalary",
          type: Number,
        },
        appointedOn: {
          prop: "appointedOn",
          type: Date,
        },
        basicSalary: {
          prop: "basicSalary",
          type: Number,
        },
        bonuses: {
          prop: "bonuses",
          type: Number,
        },
        totalSalary: {
          prop: "totalSalary",
          type: Number,
        },
        note: {
          prop: "note",
          type: String,
        },
        allowedLeaves: {
          prop: "allowedLeaves",
          type: Number,
        },
        joinsAt: {
          prop: "joinsAt",
          type: Date,
        },
        accountTitle: {
          prop: "accountTitle",
          type: String,
        },
        accountNumber: {
          prop: "accountNumber",
          type: String,
        },
        payMode: {
          prop: "payMode",
          type: String,
        },
      };
      // console.log("import staff....");
      const input = document.getElementById("importStaff");
      // console.log(input.files);
      await readXlsxFile(input.files[0], { schema })
        .then((rows) => {
          // `rows` is an array of rows
          this.staffData = rows.rows;
        })
        .catch((err) => {
          console.log(err);
          this.staffImporting = false;
        });
      // console.log(this.staffData);
      const chunkSize = 10000;
      let chunkLength = 0;

      for (let i = 0; i < this.staffData.length; i += chunkSize) {
        const chunk = this.staffData.slice(i, i + chunkSize);
        chunkLength += chunk.length;

        // console.log(chunk);
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Staff/SaveAll?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: chunk,
          message: "Staff data imported.",
          importing: true,
          lastChunk: chunkLength == this.staffData.length,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (!status) this.staffImporting = false;
        else if (chunkLength == this.staffData.length)
          this.staffImporting = false;
      }
    },
    move() {
      if (this.byFilter) this.Filter(this.currentPage);
      else this.LoadData();
    },
    async Filter(page) {
      // console.log("filterdata", this.filter);
      // if (this.CheckClassCurrent() == true) {
      // }
      this.dataLoading = true;
      this.$bvModal.hide("modal-login");
      this.$bvModal.hide("modal-reg");
      let url =
        this.$store.state.domain +
        "students/Filterdata?cls=" +
        this.filter.cls +
        "&sec=" +
        this.filter.sec +
        "&status=" +
        this.filter.status +
        "&db=" +
        this.$store.state.userData.db +
        "&cId=" +
        this.$store.state.userData.cId +
        "&page=" +
        page;

      if (this.currentStatus == "registered") {
        // console.log(this.filterDate);
        if (this.filterDate) {
          if (this.regFilter.dateType == "admission") {
            url = url + `&testDate=${this.filterDate}&interviewDate=`;
          } else url = url + `&testDate=&interviewDate=${this.filterDate}`;
        } else url = url + `&testDate=&interviewDate=`;
      }
      let myitem = await this.get({
        url: url,
        token: this.$store.state.userData.token,
      });
      this.items = myitem.data;
      this.total = myitem.count;
      // console.log("items", this.items);

      this.dataLoading = false;
      this.byFilter = true;
      let fData = {
        cls: this.filter.cls,
        sec: this.filter.sec,
        status: this.filter.status,
        dateType: this.regFilter.dateType,
        filterDate: this.filterDate,
        byFilter: this.byFilter,
        page: page,
        currentStatus: this.currentStatus,
        total: this.total,
        db: this.$store.state.userData.db,
        cID: this.$store.state.userData.cId,
      };
      let ind = this.filterData.findIndex(
        (el) =>
          el.db == this.$store.state.userData.db &&
          el.cID == this.$store.state.userData.cId
      );
      if (ind != -1) {
        this.filterData[ind] = { ...fData };
      } else {
        this.filterData.push(fData);
      }
      this.$store.commit("setStdFilter", this.filterData);

      // console.log(this.filterData);
    },
    openFilter() {
      if (this.currentStatus == "registered") {
        this.$bvModal.show("modal-reg");
        this.filter.status = "registered";
      } else {
        this.$bvModal.show("modal-login");
        this.filter.status = "present";
      }
    },
    CheckClassCurrent() {
      var elem = this.$refs["class_current"];
      if (this.filter.cls == 0) {
        return elem ? (elem.state = false) : false;
      } else {
        return elem ? (elem.state = true) : true;
      }
    },
    FillSectionsCurrent(text) {
      // console.log(this.filter.cls);
      this.currentSections = [];
      if (text != "filled") this.filter.sec = 0;
      if (this.filter.cls == 0) {
        this.currentSections = [{ section: "All Sections", id: 0 }];
      } else {
        this.classes.forEach((elem) => {
          if (elem.cls.id === this.filter.cls) {
            if (elem.sections.length != 0) {
              this.currentSections = [...elem.sections];
              this.currentSections.unshift({ section: "All Sections", id: 0 });
            } else {
              // console.log("null");
              this.currentSections = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      }
      // console.log("Thisis", this.currentSections);
    },
    async LoadFilter() {
      this.filterloading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      //  this.items =[];
      let myitem = await this.get(obj);
      this.classes = myitem;
      this.classesOptions = [];
      this.classes.forEach((el) => this.classesOptions.push(el.cls));
      // console.log(this.classesOptions);
      this.filterloading = false;
      if (this.filter.cls > 0) this.FillSectionsCurrent("filled");
    },

    deleteDocument(index) {
      this.myObj.attachments.splice(index, 1);
    },
    PsampleAdd() {
      // console.log(this.$refs.psamplefile.files);

      if (this.$refs.psamplefile.files.length !== 0) {
        //this.sampleloading = true;
        this.docsloading = "";
        this.mydocloading = true;
        var formdata = new FormData();
        for (var i = 0; i < this.$refs.psamplefile.files.length; i++) {
          formdata.append("file", this.$refs.psamplefile.files[i]);
        }

        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        fetch("https://upload.appick.io", requestOptions)
          .then((response) => response.json()) //2
          .then((result) => {
            //this.moreImages.push(result.myresp);

            for (var i = 0, len = result.myresp.length; i < len; ++i) {
              this.sampleloading = false;
              var fn = result.myresp[i].path;
              this.myObj.attachments.push(fn);
            }
            // console.log("Hello", this.myObj.attachments);
            this.docsloading = "Upload Documents";
            this.mydocloading = false;
          })

          .catch((error) => console.log(error));
      }
    },
    OpenImport() {
      this.visibility2 = true;
    },
    Edit(id, readmit) {
      // console.log("myRow1111111111111111", id);
      this.$router.push({
        name: "manage-student",
        params: {
          id,
          readmit,
        },
      });
      // this.myObj.id = row.id;
      // this.myObj.name = row.name;
      // this.myObj.campusID = this.$store.state.userData.cId;
      // this.myObj.father_name = row.father_name;
      // this.myObj.contact = row.contact;
      // this.myObj.email = row.email;
      // this.myObj.purpose = row.purpose;
      // this.myObj.tag = row.tag;
      // this.myObj.attachments = row.attachments.split(',');
      // this.visibility = true;
      // this.sidebarTitle = 'Edit Class';
      // this.sidebarButton = 'Update';
      // this.buttonIcon = 'EditIcon';
    },
    AddOpen(text) {
      // console.log(text);
      this.$router.push({
        name: "manage-student",
        params: {
          selected: text,
          id: 0,
        },
      });
    },
    View(item) {
      // console.log(item);
      this.$router.push({
        name: "apps-users-view",
        params: {
          id: item.id,
          item,
        },
      });
    },
    CheckMobile() {
      var re = /^(\+92|0|92)[0-9]{10}$/;
      if (re.test(this.myObj.contact) && this.myObj.contact !== "") {
        return (this.contacterror = false);
      } else {
        return (this.contacterror = true);
      }
    },
    CheckDepName() {
      var elem = this.$refs["depname"];
      if (this.depObj.name == "" || this.depObj.name > 20) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckReason() {
      var elem = this.$refs["reason"];
      if (this.leaveObj.reason.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDate() {
      var elem = this.$refs["leave_date"];
      if (
        this.leaveObj.date_of_leaving == "" ||
        this.leaveObj.date_of_leaving == null
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDepartment() {
      var elem = this.$refs["department"];
      if (this.myObj.departmentID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSections() {
      var elem = this.$refs["sections"];
      if (this.myObj.sections.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    hideSideBar() {
      this.visibility = false;
    },
    hideDepSideBar() {
      this.visibility1 = false;
      //  this.$refs.mysidebar.hide();
    },
    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "students/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Student deleted successfully.",
          context: this,
          body: null,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
      // Swal.fire({
      //   title: 'Are you sure?',
      //   text: "You won't be able to revert this!",
      //   icon: 'warning',
      //   showCancelButton: true,
      //   confirmButtonColor: '#3085d6',
      //   cancelButtonColor: '#d33',
      //   confirmButtonText: 'Yes, Delete it!',
      // }).then(result => {
      //   if (result.isConfirmed) {

      //   }
      // });
    },

    openSus(item) {
      this.susObj = {
        sID: item.id,
        suspend_till: item.suspend_till,
        suspend_reason: item.suspend_reason,
        suspend_for_attentance: item.suspend_for_attentance,
        suspend_for_homework: item.suspend_for_homework,
        suspend_for_exams: item.suspend_for_exams,
        suspend_for_messaging: item.suspend_for_messaging,
      };
      this.sidebarTitle = `${item.name}<br/>Gr: ${item.grNo}`;
      this.currentSelected = "";
      this.visibility5 = true;
      var elem = this.$refs["sus_date"];
      elem.state = undefined;
      var elem = this.$refs["sus_reason"];
      elem.state = undefined;
      var elem = this.$refs["sus_for"];
      elem.state = undefined;
    },
    setTillDate(val) {
      this.currentSelected = val;
      let date;
      if (!this.susObj.suspend_till) {
        date = new Date();
      } else {
        date = new Date(this.susObj.suspend_till);
      }
      if (val == "week") {
        this.susObj.suspend_till = new Date(
          date.getTime() + 7 * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .split("T")[0];
      } else if (val == "month") {
        date.setMonth(date.getMonth() + 1);
        this.susObj.suspend_till = date.toISOString().split("T")[0];
      } else {
        date.setFullYear(date.getFullYear() + 1);
        this.susObj.suspend_till = date.toISOString().split("T")[0];
      }
      // console.log(this.susObj.suspend_till);
    },
    CheckSusDate() {
      var elem = this.$refs["sus_date"];
      if (!this.susObj.suspend_till) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSusReason() {
      var elem = this.$refs["sus_reason"];
      if (!this.susObj.suspend_reason) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSusFor() {
      var elem = this.$refs["sus_for"];
      if (
        !this.susObj.suspend_for_attentance &&
        !this.susObj.suspend_for_exams &&
        !this.susObj.suspend_for_homework &&
        !this.susObj.suspend_for_messaging
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async Suspend() {
      // console.log(this.leaveObj);
      this.CheckSusReason();
      this.CheckSusDate();
      this.CheckSusFor();
      if (
        this.CheckSusReason() == true &&
        this.CheckSusDate() == true &&
        this.CheckSusFor() == true
      ) {
        // console.log(this.susObj);
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Students/Suspend?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.susObj,
          message: "Student suspended!",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.LoadData();
          this.visibility5 = false;
        }
      }
    },
    async ClearSuspend() {
      this.request = true;
      var status = await this.post({
        url:
          this.$store.state.domain +
          "Students/ClearSuspension?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&sID=" +
          this.susObj.sID,
        message: "suspension removed!",
        context: this,
        token: this.$store.state.userData.token,
      });
      this.request = false;
      if (status) {
        this.LoadData();
        this.visibility5 = false;
      }
    },

    async Leave(item) {
      this.leaveObj = {
        sID: item.id,
        reason: "",
        date_of_leaving: null,
      };
      await this.loadDues(item.id);
      this.sidebarTitle = `${item.name}<br/>Gr: ${item.grNo}`;
      this.visibility = true;
    },
    async loadDues(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "Fees/GetStudentBalance?db=" +
          this.$store.state.userData.db +
          "&campusID=" +
          this.$store.state.userData.cId +
          "&sID=" +
          id,
        token: this.$store.state.userData.token,
      };
      this.totalDues = await this.get(obj);
    },

    async Add() {
      // console.log(this.leaveObj);
      if (this.CheckReason() == true && this.CheckDate() == true) {
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Students/MarkLeft?db=" +
            this.$store.state.userData.db,
          body: this.leaveObj,
          message: "Student marked as left!",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.LoadData();
          this.visibility = false;
        }
      }
    },

    async LoadData(page) {
      this.dataLoading = true;
      let dt = new Date().toJSON().split("T")[0];
      if (!this.loadObj.dtFrom) this.loadObj.dtFrom = dt;
      if (!this.loadObj.dtTo) this.loadObj.dtTo = dt;
      this.rangeDate = `${this.loadObj.dtFrom} to ${this.loadObj.dtTo}`;

      if (this.campIDs[0] == 0) this.loadObj.campusIDs = [];
      else this.loadObj.campusIDs = this.campIDs;

      this.byFilter = false;
      this.items = [];
      let myitem = await this.post({
        url:
          this.$store.state.domain +
          "Students/LoadEnrolledData?db=" +
          this.$store.state.userData.db +
          "&page=" +
          page,
        body: this.loadObj,
        context: this,
        subjects: true,
        token: this.$store.state.userData.token,
      });
      // console.log(myitem);
      this.items = myitem.data;
      this.total = myitem.count;
      this.confirmObj = {
        campusIDs: [...this.loadObj.campusIDs],
        dtFrom: this.loadObj.dtFrom,
        dtTo: this.loadObj.dtTo,
      };
      this.dataLoading = false;
    },

    async loadSearch() {
      if (this.searchQuery.trim() == "") {
        this.currentPage = 1;
        this.LoadData();
      } else {
        //  this.items =[];
        this.byFilter = false;
        this.dataLoading = true;
        let myitem = await this.get({
          url:
            this.$store.state.domain +
            "Students/Search?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&keyword=" +
            this.searchQuery +
            "&type=" +
            this.searchType.replace(" ", "_"),
          token: this.$store.state.userData.token,
        });
        this.items = myitem;
        this.total = 0;
        // console.log("items", this.items);
        this.dataLoading = false;

        this.currentPage = 1;
      }
    },

    openOptions() {
      this.$bvModal.show("modal-search");
    },
    saveState(val) {
      this.searchType = val;
      this.$store.commit("setSearch", this.searchType);
      this.$bvModal.hide("modal-search");
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
<style lang="css">
.adjust-head {
  height: 2.5rem;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.search-type:hover {
  background-color: rgba(128, 116, 229, 0.12);
  color: var(--primary);
  transform: scaleY(1.03);
}

.input-group {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}

.input-group-prepend .input-group-text {
  border: none;
}

.custom-checkbox .custom-control-label {
  cursor: pointer !important;
}

.table-responsive {
  transform: rotateX(180deg);
}
.table-responsive > ::v-deep.table {
  transform: rotateX(180deg);
}
</style>
